import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import SEO from "../components/App/SEO"
import CTABanner from "../components/Common/CTABanner"

const Tmd = () => {
  return (
    <Layout>
      <SEO
        title="Target Market Determinations (TMD) | Oxygen - Home Loans Made Simple"
        // description="This Privacy Policy was last updated on 8th March 2022."
      />
      <Navbar />

      <section className="page-tmd pt-5 pb-5">
        <Container>
          <Row>
            <Col md="12">
              <div className="section-top pt-4">
                <h3 className="pt-lg-3 pt-md-0">
                  Target Market Determinations (TMD)
                </h3>
                <p>
                  We are required to make Target Market Determinations available
                  under the Treasury Laws Amendment (Design and Distribution
                  Obligations and Product Intervention Powers) Act (Cth) 2019.{" "}
                  <br />
                  This is to ensure that the right products end up in the hands
                  of the right customer by focusing on our customers in the
                  design and distribution of our financial products.
                </p>
              </div>

              <div className="section-mid">
                <h4 className="pt-lg-3 pt-md-0">
                  What is a Target Market Determination (TMD)?
                </h4>
                <p>
                  A TMD is a document which describes:
                  <ul>
                    <li>
                      the persons for which the product has been designed,
                    </li>
                    <li>the conditions around the product’s distribution,</li>
                    <li>when this TMD will be reviewed, and</li>
                    <li>
                      record keeping and reporting obligations of distributors
                    </li>
                  </ul>
                </p>
                <p>
                  Please note a TMD is not intended to provide financial advice.
                  When making a decision about a product always make sure you
                  refer to the Terms and Conditions and any supplementary
                  document(s). These will outline the relevant terms and
                  conditions being provided under that product.
                </p>
              </div>

              <div className="bottom-top">
                <h4 className="pt-lg-3 pt-md-0">Oxygen TMDs</h4>
                <p>
                  <ul>
                    <li>
                      <a
                        href={`${process.env.GATSBY_S3_URL}/documents/OxygenPrime+Variable_TMD_(2024.1)_Feb24.pdf`}
                        className="underlined-link link"
                        target="_blank"
                      >
                        Oxygen Complete Variable Rate Product (PDF)
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.GATSBY_S3_URL}/documents/SMSF_Variable_TMD_Feb24.pdf`}
                        className="underlined-link link"
                        target="_blank"
                      >
                        Oxygen SMSF Variable Rate Product (PDF)
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.GATSBY_S3_URL}/documents/Oxygen_NDIS_Variable_TMD_(2024.1)_Feb24.pdf`}
                        className="underlined-link link"
                        target="_blank"
                      >
                        Oxygen NDIS Variable Rate Product (PDF)
                      </a>
                    </li>
                  </ul>
                </p>
              </div>
              <div className="bottom-top">
                <h4 className="pt-lg-3 pt-md-0">Oxygen TMD Archive</h4>
                <p>
                  <ul>
                    <li>
                      <a
                        href={`${process.env.GATSBY_S3_URL}/documents/Oxygen_TMD_Prime_Variable_Product_2022.pdf`}
                        className="underlined-link link"
                        target="_blank"
                      >
                        Oxygen Complete Variable Rate Product to 31-Jan-2024
                        (PDF)
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.GATSBY_S3_URL}/documents/Oxygen_TMD_SMSF_Variable_Product_2022.pdf`}
                        className="underlined-link link"
                        target="_blank"
                      >
                        Oxygen SMSF Variable Rate Product to 31-Jan-2024 (PDF)
                      </a>
                    </li>
                  </ul>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <CTABanner />
      <Footer />
    </Layout>
  )
}

export default Tmd
